var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-sales-overview-table"},[_c('div',{staticClass:"container fluid"},[_c('filter-panel',{attrs:{"useToggle":""},on:{"clear":_vm.clearFilter,"filter":_vm.filterData},model:{value:(_vm.isFilterActive),callback:function ($$v) {_vm.isFilterActive=$$v},expression:"isFilterActive"}},[_c('div',{staticClass:"row p-2"},[_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-4 px-1 mb-2",attrs:{"label":"Purchase ID","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData($event)}},model:{value:(_vm.filter['id']),callback:function ($$v) {_vm.$set(_vm.filter, 'id', $$v)},expression:"filter['id']"}}),_c('fd-select',{staticClass:"col-12 sm-col-6 md-col-4 px-1 mb-2",attrs:{"label":"Status","optionLabel":function (val) { return val; },"optionValue":function (val) { return val; },"options":_vm.statusOptions},model:{value:(_vm.filter['status']),callback:function ($$v) {_vm.$set(_vm.filter, 'status', $$v)},expression:"filter['status']"}}),_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-4 px-1 mb-2",attrs:{"label":"First Purchaser Name","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData($event)}},model:{value:(
            _vm.filter['projectPurchaseReservation:purchaserName1[partial]']
          ),callback:function ($$v) {_vm.$set(_vm.filter, 'projectPurchaseReservation:purchaserName1[partial]', $$v)},expression:"\n            filter['projectPurchaseReservation:purchaserName1[partial]']\n          "}})],1)]),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.projectSaleTableColumns,"rows":_vm.projectSaleTableData,"totalRows":_vm.projectSaleTablePagination.total,"isLoading":_vm.isLoading,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.toProjectSaleDetail},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'status')?_c('span',[_c('project-sale-status',{attrs:{"status":props.row.currentPhase}})],1):(props.column.field == 'project')?_c('span',[_c('project-unit-summary',{attrs:{"sale":props.row}})],1):(props.column.field == 'buyerContact')?_c('span',{staticClass:"buyer-contact",on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-phone mr-1"}),_vm._v(_vm._s(props.row.projectPurchaseReservation.phoneContact)+" ")]),_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-envelope mr-1"}),_vm._v(_vm._s(props.row.projectPurchaseReservation.email)+" ")])]):(
              props.column.field == 'assignedAgent' &&
              !_vm._.isEmpty(props.row.agent)
            )?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('p',[_vm._v(_vm._s(props.row.agent.name))]),_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-phone mr-1"}),_vm._v(_vm._s(_vm.$getUserPhoneNumber(props.row.agent.user))+" ")]),_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-envelope mr-1"}),_vm._v(_vm._s(props.row.agent.user.email)+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('spinner')],1)]),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no project sales"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"})])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }