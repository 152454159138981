<template>
  <div class="project-sales-overview-table">
    <div class="container fluid">
      <filter-panel
        v-model="isFilterActive"
        useToggle
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="row p-2">
          <fd-input
            v-model="filter['id']"
            class="col-12 sm-col-6 md-col-4 px-1 mb-2"
            label="Purchase ID"
            type="text"
            @keyup.enter.native="filterData"
          >
          </fd-input>
          <fd-select
            v-model="filter['status']"
            class="col-12 sm-col-6 md-col-4 px-1 mb-2"
            label="Status"
            :optionLabel="(val) => val"
            :optionValue="(val) => val"
            :options="statusOptions"
          >
          </fd-select>
          <fd-input
            v-model="
              filter['projectPurchaseReservation:purchaserName1[partial]']
            "
            class="col-12 sm-col-6 md-col-4 px-1 mb-2"
            label="First Purchaser Name"
            type="text"
            @keyup.enter.native="filterData"
          >
          </fd-input>
        </div>
      </filter-panel>

      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="projectSaleTableColumns"
          :rows="projectSaleTableData"
          :totalRows="projectSaleTablePagination.total"
          :isLoading="isLoading"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
          @on-row-click="toProjectSaleDetail"
        >
          <div slot="loadingContent">
            <div class="d-flex justify-content-center">
              <spinner></spinner>
            </div>
          </div>
          <div slot="emptystate">
            <no-data message="There is no project sales"></no-data>
          </div>
          <div slot="table-actions" class="p-2"></div>
          <template slot="table-row" slot-scope="props">
            <!-- ================== PROJECT UNIT DETAILS =================== -->
            <span v-if="props.column.field == 'status'">
              <project-sale-status
                :status="props.row.currentPhase"
              ></project-sale-status>
            </span>
            <span v-else-if="props.column.field == 'project'">
              <project-unit-summary :sale="props.row"></project-unit-summary>
            </span>
            <!-- ====================== BUYER CONTACT ====================== -->
            <span
              v-else-if="props.column.field == 'buyerContact'"
              class="buyer-contact"
              @click.stop
            >
              <span class="d-flex align-items-center">
                <i class="fas fa-phone mr-1"></i
                >{{ props.row.projectPurchaseReservation.phoneContact }}
              </span>
              <span class="d-flex align-items-center">
                <i class="fas fa-envelope mr-1"></i
                >{{ props.row.projectPurchaseReservation.email }}
              </span>
            </span>
            <!-- ======================== AGENT INFO ======================= -->
            <span
              v-else-if="
                props.column.field == 'assignedAgent' &&
                !_.isEmpty(props.row.agent)
              "
              @click.stop
            >
              <p>{{ props.row.agent.name }}</p>
              <span class="d-flex align-items-center">
                <i class="fas fa-phone mr-1"></i
                >{{ $getUserPhoneNumber(props.row.agent.user) }}
              </span>
              <span class="d-flex align-items-center">
                <i class="fas fa-envelope mr-1"></i
                >{{ props.row.agent.user.email }}
              </span>
            </span>
            <!-- =========================================================== -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { projectSalesPhase as STATUS } from "@/enums";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    ProjectSaleStatus: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/ProjectSaleStatus"
      ),
    ProjectUnitSummary: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/ProjectUnitSummary"
      )
  },
  mixins: [vueGoodTable],
  props: {
    getProjectSalesAPI: {
      type: Function,
      required: true
    },
    detailRouteName: {
      type: String
    }
  },
  data: function () {
    return {
      projectSaleTableData: [],
      projectSaleTablePagination: {},
      projectSaleTableColumns: [
        {
          label: "ID",
          field: "id",
          tdClass: "fg-grey"
        },
        {
          label: "Status",
          field: "status"
        },
        {
          label: "Project Unit",
          field: "project",
          width: "550px"
        },
        {
          label: "Buyer",
          field: "projectPurchaseReservation.purchaserName1"
        },
        {
          label: "Buyer contact",
          field: "buyerContact"
        },
        {
          label: "Agent",
          field: "assignedAgent"
        },
        {
          label: "Last Updated",
          field: "updatedAt",
          formatFn: (date) => this.$moment(date).format("LLL")
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      filter: {
        status: "",
        id: "",
        "projectPurchaseReservation:purchaserName1[partial]": ""
      },
      isLoading: false,
      statusOptions: this.$mapJsonToArray(STATUS, (val) => val)
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAllProjectSales();
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllProjectSales();
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllProjectSales().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toProjectSaleDetail(event) {
      let projectSaleId = event.row.id;
      this.$router.push({
        name: this.detailRouteName,
        params: { id: projectSaleId }
      });
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllProjectSales();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllProjectSales();
    },
    // ========================== API Related =========================
    async getAllProjectSales() {
      this.isLoading = true;
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.getProjectSalesAPI({
          queries: { ...filteredParam },
          page: this.tableParams.page,
          perPage: this.tableParams.perPage
        });

        this.projectSaleTableData = this._.cloneDeep(data.data);
        this.projectSaleTablePagination = this._.cloneDeep(
          data.meta.pagination
        );
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.warn(error);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get projectSales list. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.project-sales-overview-table {
}
</style>
